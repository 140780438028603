<template>
  <div
    v-if="!coming_soon"
    div
    class="relative flex flex-col min-h-[100dvh] overflow-hidden"
  >
    <!-- Contenuto principale -->
    <div
      id="background"
      class="absolute left-0 top-0 w-full h-full -z-10"
      :style="{ backgroundImage: 'url(background.png)' }"
    ></div>

    <!-- Layer di animazione -->
    <div class="absolute inset-0 animate-fadeIn overflow-hidden z-0">
      <!-- Esempio di animazione con gradienti -->
      <div
        v-for="coin in coins"
        :key="coin.id"
        :class="['coin', coin.size]"
        :style="coin.style"
      ></div>
    </div>

    <!-- Barra superiore con sfondo bianco -->
    <div class="relative bg-white w-full z-10 py-4 shadow-lg">
      <div
        class="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0"
      >
        <!-- Logo a sinistra -->
        <div class="flex justify-center md:justify-start w-full md:w-auto">
          <img width="200" src="root_points_logo.png" alt="Logo" />
        </div>

        <!-- Testo centrale -->
        <div
          class="text-2xl md:text-3xl font-bold text-gray-800 flex items-center justify-center w-full md:w-auto"
        >
          <div v-if="wallet" class="flex flex-col items-center space-y-2">
            <div class="flex items-center">
              <img
                src="/images/rootmap/token.png"
                alt="Icon"
                class="h-6 w-6 md:h-8 md:w-8 mr-[15px]"
              />
              <div class="flex items-center">
                <div>
                  YOUR POINTS:
                  {{ points + collateralPoints + loanPoints + ignitionPoints }}
                </div>
              </div>
            </div>
            <div class="flex space-x-2">
              <div
                class="bg-white border-2 border-[#3c82f6] text-[#3c82f6] font-bold px-3 py-1 rounded-full text-sm w-[120px] text-center"
              >
                Collateral: {{ collateralPoints || 0 }}
              </div>
              <div
                class="bg-white border-2 border-[#3c82f6] text-[#3c82f6] font-bold px-3 py-1 rounded-full text-sm w-[120px] text-center"
              >
                Loan: {{ loanPoints || 0 }}
              </div>
              <div
                class="bg-white border-2 border-[#3c82f6] text-[#3c82f6] font-bold px-3 py-1 rounded-full text-sm w-[120px] text-center"
              >
                Ignition: {{ ignitionPoints || 0 }}
              </div>
            </div>
          </div>
        </div>

        <!-- Pulsanti a destra -->
        <div
          class="flex justify-center md:justify-end w-full md:w-auto gap-4 items-center"
        >
          <div>
            <button
              @click="toggleDropdown"
              class="p-2 rounded-full flex justify-center items-center"
            >
              <img src="ico_wallet.png" alt="Icon" class="h-6 w-6" />
            </button>

            <!-- Menu dropdown visibile al click -->
            <ul v-if="isDropdownOpen" class="dropdown-menu">
              <li
                v-for="wallet in rolaResult"
                :key="wallet.address"
                @click="selectWallet(wallet)"
                :class="{
                  'dropdown-item-selected':
                    wallet.address === selectedWallet.address,
                  'dropdown-item': true
                }"
              >
                <div class="wallet-label">
                  {{ wallet.label }}
                  <!-- Mostra "(current)" accanto al wallet selezionato -->
                  <span v-if="wallet.address === selectedWallet.address">
                    (current)
                  </span>
                </div>
                <div class="wallet-address">
                  {{ truncateAddress(wallet.address) }}
                </div>
              </li>
            </ul>
          </div>

          <wallet-connection
            @wallet-connected="handleWalletConnected"
            @wallet-disconnected="handleWalletDisconnected"
            @rola-result-updated="updateRolaResult"
            @select-initial-wallet="selectInitialWallet"
          ></wallet-connection>
        </div>
      </div>
    </div>

    <!-- Titolo sotto forma di immagine -->
    <div
      class="flex justify-center z-1 max-w-screen-xl flex-col mx-auto text-sm"
    >
      <div
        class="bg-yellowRadix text-gray-800 rounded-lg shadow-md pt-4 md:pt-2 pb-1 text-center flex items-center justify-center relative"
      >
        <div>
          <p class="font-bold py-3 px-3">
            Welcome to the Land of Root Points, where planting a seed today will
            ensure future rewards! To start farming Root Points, complete the
            quests below.
          </p>
          <div class="text-right">
            <p class="underline">
              <a
                class="pr-4"
                href="https://docs.rootfinance.xyz/incentive-program/root-points"
                target="_blank"
                >Discover More</a
              >
            </p>
          </div>
        </div>
        <div
          class="bg-yellowRadix absolute top-0 right-1 md:right-0.5 rounded-sm"
        >
          <button @click="removeDiv">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Sezione delle card -->
    <div class="container mx-auto px-4 pt-16 pb-8 z-1">
      <div class="grid grid-cols-1 md:grid-cols-5 gap-4">
        <div
          :class="[
            'card bg-white text-gray-800 rounded-lg shadow-md p-6 flex flex-col items-center justify-between relative',
            card.disabled ? 'disabled-card' : ''
          ]"
          v-for="card in cards"
          :key="card.id"
        >
          <div class="absolute top-2 right-2 flex justify-center items-center">
            <img src="/images/rootmap/token.png" alt="Icon" class="h-6 w-6" />
            <p class="font-bold ml-1 text-xs">{{ card.text }}</p>
          </div>
          <!-- Immagine -->
          <div class="image-container w-[120%]">
            <img :src="card.img" alt="Icon" class="card-image" />
            <img
              class="watercolor-effect"
              :style="{
                '-webkit-mask-image': `url('/acquerello.png')`,
                'mask-image': `url('/acquerello.png', backgroundImage: 'url(acquerello.png)')`,
                'background-color': card.class
              }"
            />
          </div>

          <!-- Testo -->
          <div class="text-center font-semibold mb-4">
            {{ card.description }}
          </div>

          <!-- Pulsante Start -->
          <div v-if="wallet">
            <!-- Caso Telegram -->
            <button
              v-if="card.action === 'telegram'"
              @click="handleImmediateAction(card)"
              class="text-white py-2 px-4 rounded-full glowing-border"
              style="background-color: #3c82f6; cursor: pointer"
              :disabled="card.disabled"
            >
              {{ card.disabled ? 'Completed' : 'Start' }}
            </button>

            <!-- Caso Twitter/X -->
            <button
              v-else-if="card.action === 'twitter'"
              @click="handleImmediateAction(card)"
              class="text-white py-2 px-4 rounded-full glowing-border"
              style="background-color: #3c82f6; cursor: pointer"
              :disabled="card.disabled"
            >
              {{ card.disabled ? 'Completed' : 'Start' }}
            </button>

            <!-- Caso Referral - No NFT -->
            <a
              v-else-if="card.action === 'referral' && !userNft"
              :href="'https://app.rootfinance.xyz/'"
              target="_blank"
              class="text-white py-2 px-4 rounded-full glowing-border"
              style="background-color: #3c82f6; display: inline-block"
            >
              Make Supply
            </a>

            <!-- Caso Referral - Con NFT, No Token -->
            <button
              v-else-if="card.action === 'referral' && userNft && !tokenInUrl"
              @click="copyReferralURL"
              class="text-white py-2 px-4 rounded-full glowing-border"
              style="background-color: #3c82f6; cursor: pointer"
            >
              Copy Referral Link
            </button>

            <!-- Caso Referral - Con NFT, Con Token -->
            <button
              v-else-if="card.action === 'referral' && userNft && tokenInUrl"
              @click="referFriend"
              class="text-white py-2 px-4 rounded-full glowing-border"
              style="background-color: #3c82f6; cursor: pointer"
              :disabled="isNftAlreadyUsed"
            >
              {{ isNftAlreadyUsed ? 'Already Used' : 'Use Friend Link' }}
            </button>

            <!-- Caso Link Diretto (Deposit e Borrow) -->
            <a
              v-else-if="
                card.action === 'deposit_assets' ||
                card.action === 'borrow_assets'
              "
              :href="card.url"
              target="_blank"
              class="text-white py-2 px-4 rounded-full glowing-border"
              style="background-color: #3c82f6; display: inline-block"
            >
              Start
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Sezione delle tabelle -->
    <div class="container mx-auto px-4 py-8 z-10">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <!-- Tabella 1 -->
        <div
          class="bg-cardsBg bg-white text-gray-800 rounded-lg shadow-md p-6 overflow-hidden p-5"
        >
          <div class="max-w-xxl mx-auto">
            <h2 class="text-xl font-bold mb-4 text-left">POINTS RULES</h2>
            <p class="mb-4 text-left">
              The more you interact with Root Finance, the more points you will
              earn. The more points you have, the more rewards you will receive.
            </p>
            <h3 class="text-lg font-bold mb-4 text-left">
              START EARNING POINTS
            </h3>
            <p class="mb-4 text-left">
              Hey Rooter! To start earning points, first complete the quests and
              lend or borrow on Root Finance. Remember this rule Rooter, for
              every $100 worth of assets deposited or borrowed, you will
              accumulate Root Points every 24 hours based on your bonus.
            </p>
            <div class="mb-4">
              <div class="flex items-center">
                <h3 class="text-lg font-bold mb-1 text-left">SUPPLY BONUS</h3>
                <img src="/icon/X1.png" alt="Icon 5" class="h-10" />
              </div>
              <div class="flex items-center justify-start space-x-2 mb-2">
                <img src="/icon/radix.png" alt="radix" class="h-6 w-6" />
                <img src="/icon/xwbtc.png" alt="wwbtc" class="h-6 w-6" />
                <img src="/icon/ether.png" alt="ether" class="h-6 w-6" />
                <img src="/icon/isu.png" alt="isu" class="h-6 w-6" />
              </div>
              <div>
                <p>
                  For every $100 supplied, you earn Root Points every 24 hours
                </p>
              </div>
            </div>
            <div class="mb-4">
              <div class="flex items-center">
                <h3 class="text-lg font-bold mb-1 text-left">SUPPLY BONUS</h3>
                <img src="/icon/X2.png" alt="Icon 5" class="h-10" />
              </div>
              <div class="flex items-center justify-start space-x-2 mb-2">
                <img src="/icon/resource.png" alt="isu" class="h-6 w-6" />
                <img src="/icon/tether.png" alt="isu" class="h-6 w-6" />
                <img src="/icon/wowo.png" alt="isu" class="h-6 w-6" />
                <img src="/icon/early.png" alt="isu" class="h-6 w-6" />
              </div>
              <div>
                <p>
                  For every $100 supplied, you earn Root Points every 24 hours
                </p>
              </div>
            </div>
            <div class="mb-4">
              <div class="flex items-center">
                <h3 class="text-lg font-bold mb-1 text-left">SUPPLY BONUS</h3>
                <img src="/icon/X3.png" alt="Icon 5" class="h-10" />
              </div>
              <div class="flex items-center justify-start space-x-2 mb-2">
                <img src="/icon/hug_new.png" alt="isu" class="h-6 w-6" />
              </div>
              <div>
                <p>
                  For every $100 supplied, you earn Root Points every 24 hours
                </p>
              </div>
            </div>

            <p class="text-xs mb-4 text-left">
              <em
                >Example: If you deposit $1000 worth of XRD you will earn 10
                Root Points every 24 hours</em
              >
            </p>
            <div class="mb-4">
              <div class="flex items-center">
                <h3 class="text-lg font-bold mb-1 text-left">BORROW BONUS</h3>
                <img src="/icon/X3.png" alt="Icon 5" class="h-10" />
              </div>
              <div class="flex items-center justify-start space-x-2 mb-2">
                <img src="/icon/radix.png" alt="radix" class="h-6 w-6" />
                <img src="/icon/xwbtc.png" alt="wwbtc" class="h-6 w-6" />
                <img src="/icon/ether.png" alt="ether" class="h-6 w-6" />
                <img src="/icon/isu.png" alt="isu" class="h-6 w-6" />
                <img src="/icon/resource.png" alt="isu" class="h-6 w-6" />
                <img src="/icon/tether.png" alt="isu" class="h-6 w-6" />
              </div>
              <div>
                <p>
                  For every $100 supplied, you earn Root Points every 24 hours
                </p>
              </div>
            </div>
            <h3 class="text-lg font-bold mb-2 text-left">REFERRAL BONUS</h3>
            <p class="text-sm mb-4 text-left">
              If you spread the Rootvolution to other users, you will earn an
              extra 10% of their points every 24 hours.
            </p>
            <p class="text-xs mb-4 text-left">
              <em
                >Example: If your friend earns 100 Root Points every 24 hours,
                you will earn an extra 10 Root Points every 24 hours.</em
              >
            </p>
            <h3 class="text-lg font-bold mb-2 text-left">
              IGNITION UNLOCK BONUS
            </h3>
            <p class="text-sm mb-4 text-left">
              Have you participated in the Liquidity Ignition and are now
              unlocking your liquidity? Supply your assets on Root Finance to
              earn a 2X more multiplier on top of the multiplier you are already
              earning.
            </p>
            <p class="text-xs mb-4 text-left">
              <em
                >Example: If you supply USDC, you earn a 2x multiplier every 24
                hours. But if you supply liquidity coming from the Ignition
                unlock, you will earn an extra 2X multiplier resulting in a 4X
                multiplier!</em
              >
            </p>
          </div>
        </div>
        <!-- Tabella 2 (Classifica Wallet) -->
        <div
          class="overflow-hidden rounded-lg p-6 bg-cardsBg bg-white text-gray-800 rounded-lg shadow-md"
        >
          <h2 class="text-xl font-bold mb-4 text-center">LEADERBOARD</h2>
          <div v-if="leaderboard && leaderboard.length" class="p-5">
            <!-- Posizione personale se non nei top 10 -->
            <div
              v-if="userPosition && !userInTop10"
              class="mb-4 p-4 bg-blue-50 rounded-lg"
            >
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-2">
                  <span class="font-semibold">Your Position:</span>
                  <span class="text-blue-600">#{{ userPosition }}</span>
                </div>
                <div class="flex items-center gap-2">
                  <span class="font-semibold">Points:</span>
                  <span class="text-blue-600">{{
                    leaderboard.find(
                      item =>
                        item.nftId === userNFT || `#${item.nftId}#` === userNFT
                    )?.totalPoints || 0
                  }}</span>
                </div>
              </div>
            </div>

            <table class="min-w-full">
              <thead>
                <tr>
                  <td class="px-1 mx-auto pb-6 pl-0">
                    <div class="w-full flex justify-start">
                      <span
                        class="align-middle font-semibold text-sm uppercase whitespace-nowrap"
                      >
                        wallet
                      </span>
                    </div>
                  </td>
                  <td class="px-1 mx-auto pb-6">
                    <div class="w-full flex justify-center">
                      <span
                        class="align-middle font-semibold text-sm uppercase whitespace-nowrap"
                      >
                        points
                      </span>
                    </div>
                  </td>
                  <td class="px-1 mx-auto pb-6">
                    <div class="w-full flex justify-center">
                      <span
                        class="align-middle font-semibold text-sm uppercase whitespace-nowrap"
                      >
                        pos
                      </span>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in leaderboard"
                  :key="item.nftId + (item.isUserPosition ? '-user' : '')"
                  :class="[
                    'transition-colors h-12',
                    item.nftId === userNFT || `#${item.nftId}#` === userNFT
                      ? 'bg-blue-50'
                      : '',
                    item.isUserPosition ? 'border-t-2 border-blue-200 mt-2' : ''
                  ]"
                >
                  <td class="px-1 mx-auto pl-1">
                    <div class="flex items-center gap-2">
                      <span
                        :class="[
                          'align-middle font-normal text-base',
                          item.nftId === userNFT ||
                          `#${item.nftId}#` === userNFT
                            ? 'text-blue-600 font-semibold'
                            : ''
                        ]"
                      >
                        {{ truncateWallet(item.wallet) }}
                      </span>
                      <span
                        v-if="
                          item.nftId === userNFT ||
                          `#${item.nftId}#` === userNFT
                        "
                        class="text-xs bg-blue-100 text-blue-600 px-2 py-1 rounded"
                      >
                        Your Root Receipt
                      </span>
                    </div>
                  </td>
                  <td class="px-1 mx-auto">
                    <div class="flex justify-center w-full">
                      <span
                        :class="[
                          'align-middle font-normal text-base whitespace-nowrap',
                          item.nftId === userNFT ||
                          `#${item.nftId}#` === userNFT
                            ? 'text-blue-600 font-semibold'
                            : ''
                        ]"
                      >
                        {{ item.totalPoints }}
                      </span>
                    </div>
                  </td>
                  <td class="px-1 mx-auto">
                    <div class="flex justify-center w-full">
                      <span
                        :class="[
                          'align-middle font-normal text-base whitespace-nowrap',
                          item.nftId === userNFT ||
                          `#${item.nftId}#` === userNFT
                            ? 'text-blue-600 font-semibold'
                            : ''
                        ]"
                      >
                        {{ item.isUserPosition ? userPosition : index + 1 }}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div
      class="flex items-center justify-center h-screen bg-gradient-to-b from-[#631c5c] to-[#183a62]"
    >
      <div class="text-center w-full h-full">
        <img
          src="/coming.png"
          alt="Coming Soon"
          class="mx-auto mb-4 w-full h-full object-contain"
        />
      </div>
    </div>
  </div>
</template>

<script>
const API_URL = process.env.VUE_APP_API_URL;
import { selectedWallet } from './walletStore';
import axios from 'axios';
import WalletConnection from './components/WalletConnection.vue';
import { useToast } from 'vue-toastification';

export default {
  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast, selectedWallet };
  },
  components: {
    WalletConnection
  },
  name: 'App',
  data() {
    return {
      rolaResult: [],
      activeWallet: null,
      isDropdownOpen: false,
      coming_soon: false,
      collateralPoints: 0,
      loanPoints: 0,
      ignitionPoints: 0,
      cards: [
        {
          id: 1,
          description: 'Join our Telegram',
          action: 'telegram',
          img: 'telegram.png',
          class: '#bdd6ff',
          text: '+10',
          disabled: false,
          url: ''
        },
        {
          id: 2,
          description: 'Follow us on X',
          action: 'twitter',
          img: 'twitter.png',
          class: '#c4c2c2',
          text: '+10',
          disabled: false,
          url: ''
        },
        {
          id: 3,
          description: 'Deposit al least 200 USD worth of Assets',
          action: 'deposit_assets',
          img: 'deposit.png',
          class: '#edc1f7',
          text: 'Daily',
          url: 'https://app.rootfinance.xyz'
        },
        {
          id: 4,
          description: 'Borrow at least 100 USD worth of assets',
          action: 'borrow_assets',
          img: 'borrow.png',
          class: '#aae3b9',
          text: 'Daily',
          url: 'https://app.rootfinance.xyz'
        },
        {
          id: 5,
          description: 'Copy your link & get 10% extra points from referrals',
          action: 'referral',
          img: 'refer.png',
          class: '#e398a1',
          text: '%10',
          disabled: false,
          url: 'https://app.rootfinance.xyz'
        }
      ],
      coins: [],
      coinId: 0,
      hasAccess: false,
      wallet: '',
      referralURL: '',
      account_rdx: '',
      points: 0,
      leaderboard: [],
      userNft: 0,
      nftPoints: 0,
      tokenInUrl: null,
      userPosition: null,
      totalParticipants: null,
      userInTop10: false,
      isNftAlreadyUsed: false
    };
  },
  methods: {
    async nftUser(address) {
      try {
        const response = await axios.post(`${API_URL}/api/nft-user`, {
          wallet: address
        });
        this.userNft = response.data.nft_ids?.[0] || 0;
        this.updateReferralCard();
        // Aggiorna i punti quando cambia l'NFT
        await this.updateNftPoints();
      } catch (error) {
        console.error('Error retrieving NFTs:', error);
        this.userNft = 0;
        this.updateReferralCard();
      }
    },

    async fetchNonFungibleLocation() {
      try {
        const response = await axios.post(
          `${API_URL}/api/non-fungible-location`,
          {
            resourceAddress:
              'resource_rdx1ngekvyag42r0xkhy2ds08fcl7f2ncgc0g74yg6wpeeyc4vtj03sa9f',
            nonFungibleId: '#7#'
          }
        );
        let result = response.data;
        console.log(result);
      } catch (error) {
        console.error(error.response || error.message);
      }
    },

    toggleDropdown() {
      if (this.rolaResult.length > 0) {
        this.isDropdownOpen = !this.isDropdownOpen;
      } else {
        console.log('No address available in rolaResult');
      }
    },
    selectInitialWallet(address) {
      this.selectedWallet.address = address; // Imposta il wallet principale in App.vue
      localStorage.setItem('selectedWalletAddress', address); // Salva nel localStorage
    },
    selectWallet(wallet) {
      if (wallet) {
        console.log(`Switching to wallet: ${wallet.address}`);

        // Salva il wallet precedente per riferimento
        const previousWallet = this.wallet;

        // Aggiorna il wallet selezionato
        selectedWallet.address = wallet.address;
        this.wallet = selectedWallet.address;
        localStorage.setItem('selectedWalletAddress', wallet.address);

        // Verifica se il wallet è cambiato
        if (previousWallet !== wallet.address) {
          console.log(
            `Wallet changed from ${previousWallet} to ${wallet.address}`
          );
        }

        // Aggiorna le informazioni sull'NFT
        this.nftUser(wallet.address);

        // Aggiorna i dati del wallet e la leaderboard
        this.updateWalletAndLeaderboard();

        this.isDropdownOpen = false;
      }
    },
    updateRolaResult(accounts) {
      //console.log('Evento ricevuto in App.vue con accounts:', accounts);
      this.rolaResult = accounts || [];
    },
    truncateAddress(address) {
      if (address.length > 10) {
        return `${address.slice(0, 6)}...${address.slice(-4)}`;
      }
      return address;
    },
    async checkWalletStatus() {
      try {
        const response = await axios.post(`${API_URL}/api/save-wallet`, {
          wallet: this.wallet
        });

        if (response.data.success) {
          // Aggiorna lo stato con tutti i dati del wallet
          this.points = response.data.points;
          this.referralURL = response.data.referralURL;
          console.log('Referral URL:', this.referralURL); // Debug log

          this.cards.forEach(card => {
            // Disabilita le card già completate
            card.disabled = response.data.actions.some(
              action => action === card.action
            );
          });

          // Aggiorna leaderboard e punti assegnati
          this.fetchLeaderboard();
          this.checkIfPointsAssigned();
        }
      } catch (error) {
        console.error('Errore nel controllo del wallet:', error);
      }
    },

    startCoinFall() {
      setInterval(this.createCoin, 300);
    },
    createCoin() {
      const randomX = (Math.random() - 0.1) * 2;
      const randomZ = (Math.random() - 0.1) * 1;
      const duration = Math.random() * 5 + 5; // Durata casuale tra 5 e 10 secondi
      const left = Math.random() * (window.innerWidth - 80) + 'px'; // Assicura che la moneta appaia all'interno della finestra
      const size = 'small';

      // Direzione casuale per la rotazione: 1 per orario, -1 per antiorario
      const rotationDirection = Math.random() < 0.5 ? 1 : -1;

      const coin = {
        id: this.coinId++,
        size: size,
        style: {
          backgroundImage: `url('./images/rootmap/token.png')`, // Sostituisci con il percorso della tua immagine
          left: left,
          '--randomX': `${randomX}px`,
          '--randomZ': `${randomZ}px`,
          '--rotation-direction': rotationDirection,
          animationDuration: `${duration}s`
        }
      };

      this.coins.push(coin);

      setTimeout(() => {
        this.coins = this.coins.filter(c => c.id !== coin.id);
      }, duration * 1000);
    },
    removeDiv() {
      document.querySelector('.bg-yellowRadix').style.display = 'none';
    },
    handleWalletConnected(address) {
      console.log(`Wallet connected: ${address}`);

      // Salva il wallet precedente per riferimento
      const previousWallet = this.wallet;

      // Aggiorna il wallet
      this.wallet = address;

      // Verifica se il wallet è cambiato
      if (previousWallet !== address) {
        console.log(`Wallet changed from ${previousWallet} to ${address}`);
      }

      // Aggiorna le informazioni sull'NFT
      this.nftUser(address);

      // Aggiorna i dati del wallet e la leaderboard
      this.updateWalletAndLeaderboard();
      this.updateReferralCard();
    },

    handleWalletDisconnected() {
      console.log('Disconnessione gestita in App.vue');

      // Resetta tutti i dati
      this.wallet = '';
      this.points = 0;
      this.userNft = 0;
      this.nftPoints = 0; // Reset dei punti NFT
      this.userPosition = null;
      this.userInTop10 = false;
      this.leaderboard = [];

      // Pulisci localStorage ma mantieni i token referral utilizzati
      const allStorageKeys = Object.keys(localStorage);
      const keysToKeep = allStorageKeys.filter(key =>
        key.startsWith('usedReferralTokens_')
      );
      const savedTokens = {};

      // Salva i token utilizzati
      keysToKeep.forEach(key => {
        savedTokens[key] = localStorage.getItem(key);
      });

      // Pulisci localStorage
      localStorage.clear();

      // Ripristina i token utilizzati
      Object.keys(savedTokens).forEach(key => {
        localStorage.setItem(key, savedTokens[key]);
      });

      this.rolaResult = [];

      this.cards.forEach(card => {
        card.disabled = false;
      });

      this.updateReferralCard();
    },

    handleImmediateAction(card) {
      if (card.action === 'telegram') {
        this.joinTelegram();
      }
      if (card.action === 'twitter') {
        this.followOnX();
      }
      if (card.action === 'referral') {
        if (this.userNft) {
          this.copyReferralURL();
        }
      }
    },

    joinTelegram() {
      this.saveWalletPoints({ action: 'telegram', points: 10 });
      window.open('https://t.me/ROOTfinanceradix', '_blank');
      // Disabilita la card di Telegram
      const telegramCard = this.cards.find(card => card.action === 'telegram');
      if (telegramCard) {
        telegramCard.disabled = true;
      }
    },
    followOnX() {
      this.saveWalletPoints({ action: 'twitter', points: 10 });
      window.open('https://x.com/RootFinanceXRD', '_blank');
      // Disabilita la card di Twitter/X
      const twitterCard = this.cards.find(card => card.action === 'twitter');
      if (twitterCard) {
        twitterCard.disabled = true;
      }
    },

    async saveWalletPoints({ action, points }) {
      await axios.post(`${API_URL}/api/data`, {
        wallet: this.wallet,
        points: points,
        action: action
      });

      this.updateWalletAndLeaderboard();
    },
    async checkIfPointsAssigned() {
      try {
        const tokenParam = this.tokenInUrl
          ? `&referralToken=${this.tokenInUrl}`
          : '';

        const response = await axios.get(
          `${API_URL}/api/check_points?wallet=${this.wallet}${tokenParam}`
        );

        if (response.data.success) {
          this.points = response.data.points;
          const completedActions = response.data.actions;

          completedActions.forEach(action => {
            // Non disabilitiamo mai la card referral
            if (action !== 'referral') {
              this.disableCardByAction(action);
            }
          });
        }
      } catch (error) {
        console.error('Errore durante il controllo dei punti:', error);
      }
    },

    disableCardByAction(action) {
      const card = this.cards.find(c => c.action === action);
      if (card) {
        card.disabled = true;
      }
    },
    enableCardByAction(action) {
      const card = this.cards.find(c => c.action === action);
      if (card) {
        card.disabled = false;
      }
    },
    async fetchLeaderboard() {
      try {
        const response = await axios.get(
          `${API_URL}/api/leaderboard?wallet=${this.wallet}`
        );
        if (response.data.success) {
          this.leaderboard = response.data.leaderboard;
          this.userPosition = response.data.userPosition;
          this.userNFT = response.data.userNFT;
          this.totalParticipants = response.data.totalParticipants;
          this.userInTop10 = response.data.userInTop10;
        }
      } catch (error) {
        console.error('Errore nel recupero della leaderboard:', error);
      }
    },
    truncateWallet(wallet) {
      // Controlla se il dispositivo è mobile (larghezza viewport < 768px)
      const isMobile = window.innerWidth < 768;

      if (isMobile) {
        // Su mobile, mostra solo le ultime 13 lettere
        return `...${wallet.slice(-13)}`;
      } else {
        // Su desktop, mantieni il comportamento attuale
        if (wallet.length > 30) {
          const start = wallet.substring(0, 15);
          const end = wallet.substring(wallet.length - 15);
          return `${start}...${end}`;
        }
        return wallet;
      }
    },
    copyReferralURL() {
      console.log('Copying URL:', this.referralURL); // Debug log
      if (!this.referralURL) {
        this.toast('Referral URL not available yet', {
          type: 'default',
          timeout: 1500,
          position: 'top-center',
          className: 'toast-color'
        });
        return;
      }

      navigator.clipboard
        .writeText(this.referralURL)
        .then(() => {
          this.toast('Code copied!', {
            type: 'default',
            timeout: 1500,
            position: 'top-center',
            className: 'toast-color'
          });
        })
        .catch(err => {
          console.error('Error copying code:', err);
          this.toast('Error copying code', {
            type: 'default',
            timeout: 1500,
            position: 'top-center',
            className: 'toast-color'
          });
        });
    },
    extractReferralToken() {
      const path = window.location.pathname;
      const token = path.length > 1 ? path.substring(1) : null;

      if (token) {
        this.tokenInUrl = token;
        this.updateReferralCard();
      } else {
        this.tokenInUrl = null;
        this.updateReferralCard();
      }
    },
    referFriend() {
      // Verifica che il wallet sia effettivamente connesso
      if (!this.wallet) {
        console.log('Referral aborted: No wallet connected');
        this.toast('Please connect your wallet first', {
          type: 'default',
          timeout: 2500,
          position: 'top-center',
          className: 'toast-color'
        });
        return;
      }

      // Verifica che ci sia un token nell'URL
      if (!this.tokenInUrl) {
        console.log('Referral aborted: No token in URL');
        this.toast('No referral link detected', {
          type: 'default',
          timeout: 2500,
          position: 'top-center',
          className: 'toast-color'
        });
        return;
      }

      console.log(
        `Attempting referral with wallet: ${this.wallet} and token: ${this.tokenInUrl}`
      );

      // Chiamata al backend per assegnare i punti usando il token referral
      axios
        .post(`${API_URL}/api/refer_friend`, {
          wallet: this.wallet,
          referralToken: this.tokenInUrl
        })
        .then(response => {
          if (response.data.success) {
            // Salva il token come utilizzato per questo wallet
            const usedTokens = JSON.parse(
              localStorage.getItem(`usedReferralTokens_${this.wallet}`) || '[]'
            );
            if (!usedTokens.includes(this.tokenInUrl)) {
              usedTokens.push(this.tokenInUrl);
              localStorage.setItem(
                `usedReferralTokens_${this.wallet}`,
                JSON.stringify(usedTokens)
              );
              console.log(
                `Token ${this.tokenInUrl} marked as used for wallet ${this.wallet}`
              );
            }

            this.toast(response.data.message || 'Referral successful!', {
              type: 'default',
              timeout: 1500,
              position: 'top-center',
              className: 'toast-color'
            });
            this.updateWalletAndLeaderboard(); // Funzione separata per aggiornare wallet e leaderboard
          } else {
            // Mostra un messaggio più descrittivo in base all'errore
            let errorMessage =
              response.data.message || 'Error processing referral';

            this.toast(errorMessage, {
              type: 'default',
              timeout: 2500, // Tempo più lungo per leggere l'errore
              position: 'top-center',
              className: 'toast-color'
            });

            console.log('Referral error:', errorMessage);
          }
        })
        .catch(error => {
          console.error('Error assigning points:', error);
          this.toast('Error connecting to server. Please try again later.', {
            type: 'default',
            timeout: 2500,
            position: 'top-center',
            className: 'toast-color'
          });
        });
    },
    async updateWalletAndLeaderboard() {
      console.log('Aggiornamento wallet e leaderboard');
      await this.checkWalletStatus();
      await this.fetchLeaderboard();
      await this.checkIfPointsAssigned();
      await this.fetchNftPoints(); // Aggiungiamo il recupero dei punti NFT
    },
    updateReferralCard() {
      const referralCard = this.cards.find(card => card.action === 'referral');
      if (!referralCard) return;

      const hasNFT = this.userNft !== 0;
      const hasToken = this.tokenInUrl !== null;

      if (!hasNFT) {
        if (!hasToken) {
          // Caso 1: No NFT, No Token
          referralCard.description =
            'Deposit $200+ to unlock your referral link & earn extra points';
          referralCard.url = 'https://app.rootfinance.xyz/';
        } else {
          // Caso 2: No NFT, Con Token
          referralCard.description =
            "Deposit $200+ to claim your points & boost your referrer's bonus";
          referralCard.url = 'https://app.rootfinance.xyz/';
        }
      } else if (!hasToken) {
        referralCard.description =
          'Copy your link & get 10% extra points from referrals';
        referralCard.url = '';
        // NUOVO: Se la card è disabilitata, cambia il testo
        if (referralCard.disabled) {
          referralCard.description = 'NFT already used as referral';
        }
      } else {
        // Caso 4: Con NFT, Con Token
        referralCard.description = 'Refer a friend';
        referralCard.url = '';
      }
    },
    async fetchNftPoints() {
      if (!this.wallet) {
        console.log('fetchNftPoints: No wallet connected');
        return;
      }

      try {
        console.log('fetchNftPoints: Fetching points for wallet:', this.wallet);
        const response = await axios.post(`${API_URL}/api/nft-user`, {
          wallet: this.wallet
        });

        console.log('=== NFT Points Frontend Debug (fetchNftPoints) ===');
        console.log('Wallet:', this.wallet);
        console.log('Response:', response.data);
        console.log('Total Points:', response.data.totalPoints);
        console.log('Collateral Points:', response.data.collateralPoints);
        console.log('Loan Points:', response.data.loanPoints);
        console.log('Ignition Points:', response.data.ignitionPoints);
        console.log('NFT IDs:', response.data.nft_ids);
        console.log('=====================');

        // Aggiorna i punti solo se abbiamo una risposta valida
        if (response.data) {
          // Ora total points include già tutti i tipi di punti dal backend
          this.nftPoints = response.data.totalPoints || 0;
          this.collateralPoints = response.data.collateralPoints || 0;
          this.loanPoints = response.data.loanPoints || 0;
          this.ignitionPoints = response.data.ignitionPoints || 0;

          console.log('Updated points:', {
            nftPoints: this.nftPoints,
            collateralPoints: this.collateralPoints,
            loanPoints: this.loanPoints,
            ignitionPoints: this.ignitionPoints
          });
        }
      } catch (error) {
        console.error('Errore nel recupero dei punti NFT:', error);
        // Reset dei punti in caso di errore
        this.nftPoints = 0;
        this.collateralPoints = 0;
        this.loanPoints = 0;
        this.ignitionPoints = 0;
      }
    },
    async updateNftPoints() {
      if (!this.wallet) {
        console.log('updateNftPoints: No wallet connected');
        return;
      }

      try {
        console.log(
          'updateNftPoints: Updating points for wallet:',
          this.wallet
        );
        const response = await axios.post(`${API_URL}/api/nft-user`, {
          wallet: this.wallet
        });

        console.log('=== NFT Points Frontend Debug (updateNftPoints) ===');
        console.log('Wallet:', this.wallet);
        console.log('Response:', response.data);
        console.log('Total Points:', response.data.totalPoints);
        console.log('Collateral Points:', response.data.collateralPoints);
        console.log('Loan Points:', response.data.loanPoints);
        console.log('Ignition Points:', response.data.ignitionPoints);
        console.log('NFT IDs:', response.data.nft_ids);
        console.log('=====================');

        // Aggiorna i punti solo se abbiamo una risposta valida
        if (response.data) {
          // Ora total points include già tutti i tipi di punti dal backend
          this.nftPoints = response.data.totalPoints || 0;
          this.collateralPoints = response.data.collateralPoints || 0;
          this.loanPoints = response.data.loanPoints || 0;
          this.ignitionPoints = response.data.ignitionPoints || 0;

          console.log('Updated points:', {
            nftPoints: this.nftPoints,
            collateralPoints: this.collateralPoints,
            loanPoints: this.loanPoints,
            ignitionPoints: this.ignitionPoints
          });
        }
      } catch (error) {
        console.error('Errore nel recupero dei punti NFT:', error);
        // Reset dei punti in caso di errore
        this.nftPoints = 0;
        this.collateralPoints = 0;
        this.loanPoints = 0;
        this.ignitionPoints = 0;
      }
    }
  },
  created() {
    const savedWalletAddress = localStorage.getItem('selectedWalletAddress');
    if (savedWalletAddress) {
      this.selectedWallet.address = savedWalletAddress; // Imposta il wallet salvato
    }
  },
  mounted() {
    this.startCoinFall();
    this.extractReferralToken();

    console.log(
      `App mounted. Token in URL: ${this.tokenInUrl}, Wallet: ${this.wallet}`
    );
  }
};
</script>

<style scoped>
.dropdown-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  padding: 8px 0;
  width: 240px;
}

.dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-item-selected {
  background-color: #e0f2fe; /* Colore di evidenziazione */
  font-weight: bold;
  color: #3c82f6; /* Colore del testo */
  border-left: 4px solid #3c82f6; /* Barra laterale per evidenziare */
}

.dropdown-item:hover {
  background-color: #f0f4f8;
}

.wallet-label {
  font-weight: bold;
  color: #3c82f6;
}

.wallet-address {
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 2px;
}
.toast-color {
  background-color: rgb(60, 130, 246);
  color: #fff;
}
.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombreggiatura fissa */
}

.card:hover {
  transform: scale(1.1); /* Ingrandimento all'hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Ombreggiatura più intensa all'hover */
}

.card:hover .glowing-border::before {
  animation: glowing 2s linear infinite;
  opacity: 1; /* Rendi visibile il bordo luminoso */
}

.glowing-border {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

@keyframes glowing {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.coin {
  position: absolute;
  top: -80px; /* Inizia appena fuori dalla parte superiore della finestra */
  background-size: contain;
  background-repeat: no-repeat;
  animation: fall linear infinite;
  transform-style: preserve-3d; /* Mantieni l'effetto 3D */
}

.coin.large {
  width: 80px;
  height: 80px;
}

.coin.small {
  width: 40px;
  height: 40px;
}

@keyframes fall {
  0% {
    transform: translateY(0) translateX(0) translateZ(0) rotateZ(0deg);
    opacity: 1;
  }
  50% {
    transform: translateY(50vh) translateX(var(--randomX))
      translateZ(var(--randomZ))
      rotateZ(calc(180deg * var(--rotation-direction)));
    opacity: 1;
  }
  100% {
    transform: translateY(calc(100vh + 80px)) translateX(0) translateZ(0)
      rotateZ(calc(360deg * var(--rotation-direction)));
    opacity: 0;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flex-1 {
  flex: 1;
}
#background {
  background-repeat: no-repeat;
  background-size: cover;
}

.wallet-image {
  width: 32px; /* Modifica queste dimensioni in base alle tue esigenze */
  height: 32px;
  object-fit: contain; /* Mantiene le proporzioni dell'immagine */
}

.wallet-image-placeholder {
  width: 32px; /* Stesse dimensioni dell'immagine */
  height: 32px;
  text-align: center;
}

.image-container {
  position: relative;
  display: inline-block;
}

.card-image {
  position: relative;
  z-index: 1;
}

:deep(.watercolor-effect) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
}

.red {
  background-color: red; /* Applica colore rosso alla maschera */
}

.green {
  background-color: green; /* Applica colore verde alla maschera */
}

.blue {
  background-color: blue; /* Applica colore blu alla maschera */
}

.yellow {
  background-color: yellow; /* Applica colore giallo alla maschera */
}

.disabled-card {
  pointer-events: none;
  background-color: lightgray !important;
}

.disabled-card button {
  background-color: gray !important;
  cursor: not-allowed;
}

.disabled-card .watercolor-effect {
  opacity: 0.4;
}

.disabled-card .card-image {
  opacity: 0.6;
}
</style>
