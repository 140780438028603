<template>
  <div>
    <radix-connect-button
      @wallet-disconnected="handleWalletDisconnected"
    ></radix-connect-button>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import {
  RadixDappToolkit,
  RadixNetwork,
  DataRequestBuilder
} from '@radixdlt/radix-dapp-toolkit';
import axios from 'axios';

export default {
  setup(_, { emit }) {
    const rolaResult = ref([]); // Inizializza rolaResult come array vuoto
    const walletAddress = ref(''); // Per memorizzare l'indirizzo principale del wallet
    const walletNFTs = ref([]); // Per memorizzare gli NFT del wallet

    const handleWalletDisconnected = () => {
      console.log('Disconnessione avviata');

      walletAddress.value = '';
      rolaResult.value = [];
      walletNFTs.value = [];
      localStorage.clear();
      emit('wallet-disconnected');
    };

    const getNFTsForWallet = async address => {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/nft-user`,
          {
            wallet: address
          }
        );
        walletNFTs.value = response.data.nft_ids || [];
        localStorage.setItem('walletNFTs', JSON.stringify(walletNFTs.value));
        emit('nfts-updated', walletNFTs.value);
        return response.data;
      } catch (error) {
        console.error('Errore nel recupero degli NFT:', error);
        walletNFTs.value = [];
        return { nft_ids: [] };
      }
    };

    const initializeWalletConnection = () => {
      const savedWallets = JSON.parse(
        localStorage.getItem('walletAccounts') || '[]'
      );
      const savedNFTs = JSON.parse(localStorage.getItem('walletNFTs') || '[]');

      if (Array.isArray(savedWallets) && savedWallets.length) {
        rolaResult.value = savedWallets;
        walletAddress.value = savedWallets[0].address;
        walletNFTs.value = savedNFTs;
        emit('rola-result-updated', rolaResult.value);
        emit('wallet-connected', walletAddress.value);
        emit('nfts-updated', walletNFTs.value);
      }
    };

    // Salva rolaResult e NFTs in localStorage ogni volta che cambiano
    watch([rolaResult, walletNFTs], ([newRola, newNFTs]) => {
      localStorage.setItem('walletAccounts', JSON.stringify(newRola));
      localStorage.setItem('walletNFTs', JSON.stringify(newNFTs));
    });

    onMounted(() => {
      const radixDappToolkit = RadixDappToolkit({
        dAppDefinitionAddress:
          'account_rdx12ykkpf2v0f3hdqtez9yjhyt04u5ct455aqkq5scd5hlecwf20hcvd2',
        networkId: RadixNetwork.Mainnet,
        applicationName: 'rootpoints'
      });

      radixDappToolkit.walletApi.setRequestData(
        DataRequestBuilder.persona().withProof(),
        DataRequestBuilder.accounts().atLeast(1).withProof()
      );

      initializeWalletConnection();

      const getChallenge = async () => {
        try {
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/api/create-challenge`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
              }
            }
          );

          if (!response.ok) {
            const errorData = await response.text();
            console.error('Server response:', errorData);
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          if (!data.challenge) {
            throw new Error('Challenge not found in response');
          }

          return data.challenge;
        } catch (error) {
          console.error('Errore nel recupero della challenge:', error);
          throw error;
        }
      };

      radixDappToolkit.walletApi.provideChallengeGenerator(getChallenge);

      radixDappToolkit.walletApi.dataRequestControl(async ({ accounts }) => {
        if (accounts && accounts.length > 0) {
          rolaResult.value = accounts.map(account => ({
            label: account.label,
            address: account.address
          }));
          walletAddress.value = rolaResult.value[0].address;

          // Recupera gli NFT per il wallet connesso
          const nftData = await getNFTsForWallet(walletAddress.value);

          // Emetti gli eventi
          emit('rola-result-updated', rolaResult.value);
          emit('wallet-connected', walletAddress.value);
          emit('select-initial-wallet', walletAddress.value);
          emit('nfts-updated', nftData.nft_ids);
        }

        radixDappToolkit.walletApi.on('disconnect', () => {
          handleWalletDisconnected();
        });
      });

      const radixConnectButton = document.querySelector('radix-connect-button');
      radixConnectButton.addEventListener(
        'onDisconnect',
        handleWalletDisconnected
      );
    });

    onUnmounted(() => {
      const radixConnectButton = document.querySelector('radix-connect-button');
      if (radixConnectButton) {
        radixConnectButton.removeEventListener(
          'onDisconnect',
          handleWalletDisconnected
        );
      }
    });

    return {
      rolaResult,
      walletAddress,
      walletNFTs,
      RadixNetwork,
      handleWalletDisconnected,
      getNFTsForWallet
    };
  }
};
</script>

<style scoped>
.logo {
  height: 40px;
  margin: 10px;
}
</style>
